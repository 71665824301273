<template>
  <div>
    <modal
      :show.sync="modal"
      :backgroundColor="'#E8E8E8'"
      :showClose="false"
      bodyClasses="p-0"
      size="md"
    >
      <template slot="header">
        <div style="display: flex; align-items: center">
          <img
            src="/img/icons/icons8/ios/order-history_secondary.png"
            alt="history"
            width="24"
          />
          <h5 class="modal-title new-default-black-font m-0 ml-3">{{ title }}</h5>
        </div>
      </template>
      <template slot="close-button">
        <AppButton
          icon="close"
          type="link"
          width="auto"
          size="sm"
          :hasOutline="true"
          @click.prevent="close"
        />
      </template>
      <div class="container px-3 py-2">
        <div class="row" v-for="(item, index) in history" :key="index">
          <div class="col-2 py-2 mt-1">
            <!-- Linha vertical com efeito de Threads -->
            <div class="history-vertical-line" v-if="history.length > 1 && index+1 !== history.length"></div>
            <div class="history-step">
              <div class="history-step-icon">
                <!-- Ícone circulado -->
                <div class="history-circle-icon">
                  <img :src="classes[item.status].icon" alt="check" width="13">
                </div>
                <!-- Bubble Effect -->
                <div class="history-step-ellipsis">
                  <nav class="circle-1"></nav>
                  <nav class="circle-2"></nav>
                  <nav class="circle-3"></nav>
                </div>
              </div>
            </div>
          </div>
          <div class="col-10">
            <!-- Card das informações de cada etapa do histórico -->
            <div class="history-step-infos">
              <h4 class="new-default-black-font m-0">
                {{classes[item.status].name}}
              </h4>
              <h6 v-if="item.user_name" class="new-default-gray-font font-weight-normal m-0">
                {{ item.user_name }}
              </h6>
              <h6 v-if="item.created_at" class="new-default-gray-font font-weight-normal m-0">
                {{ item.created_at | parseDate('DD/MM/YYYY') }}
                |
                {{ item.created_at | parseDate('HH:mm') }}
              </h6>
              <div
                v-if="item.data.nfse && item.data.nfse.nfse_number"
                class="nfe-spot"
                :class="classes[item.status].class"
                @click="openDanfseOnNewTab(item.data.nfse.link_pdf)">
                <div>
                  <img :src="classes[item.status].icon" alt="nfe" width="15" style="display: inline-flex">
                  <h5 class="m-0 font-weight-normal" style="display: inline-flex">
                    {{ formatNfse(item.data.nfse) }}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr class="my-3">
      <div class="container px-4 mb-3">
        <div class="row">
          <!-- Botão para Acessar contrato (Sempre visível) -->
          <div class="offset-8 col-4 px-2">
            <AppButton
              label="Fechar"
              type="primary"
              :hasOutline="true"
              :isBlock="true"
              @click.prevent="close"
            />
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { formatErrorValidation } from "@/plugins";
import {base_url_ms} from '@/plugins'
import {AppButton} from "@/components/AppGlobal";

const classes = {
  0: {
    name: 'Pendente',
    icon: '/img/icons/icons8/ios/hourglass_warning.png',
    class: 'warning',
  },
  1: {
    name: 'EMISSÃO DA NFS-E',
    icon: '/img/icons/nfe_blue_cropped.png',
    class: 'primary',
  },
  2: {
    name: 'NFS-E Autorizada',
    icon: '/img/icons/nfe_green_cropped.png',
    class: 'success',
  },
  3: {
    name: 'NFS-E Negada',
    icon: '/img/icons/nfe_red_cropped.png',
    class: 'danger',
  },
  4: {
    name: 'CANCELAMENTO DE NFS-E',
    icon: '/img/icons/nfe_red_cropped.png',
    class: 'danger',
  },
  5: {
    name: 'Cancelamento de NFS-E Negado',
    icon: '/img/icons/nfe_red_cropped.png',
    class: 'warning',
  },
  6: {
    name: 'Emissão da Fatura',
    icon: '/img/icons/icons8/ios/check--v1_primary.png',
    class: 'primary',
  },
  7: {
    name: "Mensagem para o whatsapp enviada",
    icon: '/img/icons/whatsapp--v1.png',
    class: 'success'
  },
  8: {
    name: "Divergência da fatura constatada",
    icon: '/img/icons/nfe_red_cropped.png',
    class: 'danger'
  },
  9: {
    name: "Emissão da nfse autorizada",
    icon: '/img/icons/icons8/ios/nfe_green_cropped.png',
    class: 'success'
  },
  10: {
    name: 'Estorno de fatura',
    icon: '/img/icons/icons8/ios/check_danger.png',
    class: 'danger',
  },
  11: {
    name: 'IMPORTAÇÃO DA NFS-E',
    icon: '/img/icons/nfe_blue_cropped.png',
    class: 'primary',
  },
  12: {
    name: 'SUBSTITUIÇÃO DA NFS-E',
    icon: '/img/icons/nfe_yellow_cropped.png',
    class: 'warning',
  },
  13: {
    name: 'NFS-E Cancelada Importada',
    icon: '/img/icons/nfe_red_cropped.png',
    class: 'danger',
  },
  15: {
    name: 'ALTERAÇÃO DA REGRA DE ISS',
    icon: '/img/icons/ledger.png',
    class: 'secondary',
  },
}
export default {
  components: {AppButton},
  name: "ModalInvoiceHistory",
  data() {
    return {
      modal: false,
      title: 'Histórico da fatura',
      history: null,
      classes: classes,
      base_url_ms: base_url_ms(),
    }
  },
  methods: {
    close() {
      this.modal = false;
    },
    openModal(invoiceId) {
      this.$Progress.start();
      let loader = this.$loading.show();
      this.$store
        .dispatch("billingInvoice/getInvoiceHistory", invoiceId)
        .then((response) => {
          this.history = response.data;
          this.$notify({type: response.error_type, message: response.message});
          this.modal = true;
        })
        .catch(error => {
          const errors = error.status
            ? error.data.message
            : formatErrorValidation(error.response.data.errors);
          this.$notify({ type: "danger", message: errors });
        }).finally(() => {
          this.$Progress.finish();
          loader.hide();
        });
    },
    formatNfse(nfse) {
      // Supondo que $treatedNfseRow seja um objeto em JavaScript
      const nfseNumber = nfse.nfse_number.toString();
      const lastFiveDigits = nfseNumber.slice(-5);
      const formatedNumber = lastFiveDigits.padStart(5, '0');

      // Supondo que $treatedNfseRow.authorization_date seja uma data válida em JavaScript
      const currentDate = new Date(nfse.authorization_date);
      const currentYear = currentDate.getFullYear();
      return `${currentYear}${formatedNumber}`;
    },
    async openDanfseOnNewTab(pathFile) {
      let loader = this.$loading.show();
      const urlFromAmazon = pathFile.includes('s3');
      if (urlFromAmazon) {
        const url = this.base_url_ms + 'download-s3?url=' + pathFile;
        this.$store.dispatch('exports/download', url).then(async response => {
          let blob = new Blob([response], {type: "application/pdf"});
          let link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", 'key.pdf');
          await link.click();
        });
      } else {
        await window.open(pathFile, '_parent');
      }
      this.$snotify.success('Download iniciado com sucesso!', {
        timeout: 1000,
        icon: false,
        position: "centerBottom",
        showProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false
      });
      loader.hide();
    },
  },
};
</script>
<style scoped>
.history-vertical-line {
  background: #E8E8E8;
  width: 1px;
  height: 100%;
  position: absolute;
  left: 29px;
  top: 38px;
}

.history-step-icon {
  display: inline-flex;
  align-items: center;
}

.history-circle-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border: 1px solid #E8E8E8;
  border-radius: 50%;
  background-color: #fff;
  box-sizing: border-box;
}

.history-step-ellipsis {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.history-step-ellipsis nav {
  border: 1px solid #828282;
  border-radius: 50%;
  background-color: white;
  margin: 0 5px;
}

.circle-1 {
  width: 8px;
  height: 8px;
}

.circle-2 {
  width: 4px;
  height: 4px;
}

.circle-3 {
  width: 2px;
  height: 2px;
}

.history-step-infos {
  position: relative;
  width: 100%;
  border: 1px solid #E8E8E8;
  border-radius: 8px;
  padding: 8px 12px;
  margin: 4px 0;
}

@media (max-width: 767px) {
  .nfe-spot {
    right: 10px;
    bottom: 10px;
  }
}

@media (min-width: 768px) {
  .nfe-spot {
    right: 10px;
    top: 10px;
  }
}

.nfe-spot {
  position: absolute;
  display: flex;
  border: 1px solid #E8E8E8;
  cursor: pointer;
  border-radius: 4px;
  background-color: #fff;
  height: 26px;
  min-width: 125px;
  text-align: center;
  box-shadow: 0px 5px 10px 0px #0000000D;
}

.nfe-spot div {
  flex: auto;
  gap: 5px;
}

.nfe-spot div img, .nfe-spot div h5 {
  display: inline-flex;
  margin-left: 7px;
  margin-right: 7px;
}

.nfe-spot div h5 {
  font-size: 12px;
  color: #2B2D32;
}

.nfe-spot div:hover img, .nfe-spot div:hover h5 {
  filter: brightness(0) invert(1);
  color: white !important;
}

.nfe-spot.danger:hover {
  background-color: #db4539;
  border-color: #db4539;
}

.nfe-spot.warning:hover {
  background-color: #f2b532;
  border-color: #f2b532;
}

.nfe-spot.primary:hover {
  background-color: #1a70b7;
  border-color: #1a70b7;
}

.nfe-spot.success:hover {
  background-color: #149e57;
  border-color: #149e57;
}
</style>
